<template>
<div>
  <pt-login class="pt-login__page"></pt-login>
</div>
</template>

<script>
import PtLogin from 'cps//pt-login/withData'
export default {
  name: 'pt-login__view',
  components: {
    'pt-login': PtLogin
  }
}
</script>
