<template>
  <pt-login></pt-login>
</template>

<script>
import env from 'api'
import ptLogin from './index.vue'
export default {
  name: 'pt-login__withData',
  components: { ptLogin },
  mounted () {
  }
}
</script>
