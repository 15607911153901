<template>
  <div :class="$style[classes]">
    <div style="" class="content-message">
      <!-- <i class="iconfont icon-guanbi" :class="$style['shutdown']" @click="shutdown"></i> -->
      <img src="@/assets/images/keyclock-img-hrv.png" :class="$style['login-pf-page-header']" />
      <div :class="$style['login-pf-page-hanjing']">
        <div :class="$style['card-pf']" v-loading="loading">
          <div :class="$style['content']">
            <div :class="$style['form-item']">
              <img src="@/assets/images/user-name.png" alt="">
              <!-- <wt-input v-model="loginForm.username" placeholder="请输入用户名"></wt-input> -->
              <!-- <el-autocomplete v-model="loginForm.username" v-if="passwordMessage&&passwordMessage.length" :fetch-suggestions="querySearch" placeholder="请输入用户名" @select="handleSelect"></el-autocomplete> -->
              <wt-input v-model="loginForm.username" placeholder="请输入用户名"></wt-input>
            </div>
            <div :class="$style['form-item']">
              <img src="@/assets/images/password.png" alt="">
              <wt-input type="password" v-model="loginForm.password" placeholder="请输入密码"></wt-input>
            </div>
						<div :class="$style['button-div']">
            <button :class="$style['login-button']" @click="onLogin">登 录</button>
					</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-login'
export default {
  name: 'pt-login',
  computed: {
    classes () {
      return `${prefixCls}`
    }
  },
  data () {
    return {
      loginForm:{
        username: '',
        password: ''
      },
      loading:false,
      rememberPassword:false,
      passwordMessage:[]
    }
  },
  watch:{
    rememberPassword (){
    }
  },
  methods: {
    querySearch (queryString, cb) {
      var restaurants = JSON.parse(JSON.stringify(this.passwordMessage))
      restaurants.forEach(item => {
        item.value = item.username
      })
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter (queryString){
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect (item){
      this.loginForm.password = item.password
    },
    onLogin () {
      if(!this.loginForm.username){
        this.$message.error('请输入用户名')
        return
      }

      if(!this.loginForm.password){
        this.$message.error('请输入密码')
        return
      }
      
      this.loading = true
      // formdata格式
      var formData = new FormData();
      formData.append("username", this.loginForm.username);
      formData.append("password", this.loginForm.password);

      var qs = require('qs');
      
			this.$store.dispatch('getLogin',this.loginForm).then(res=>{
				this.$message.success('登录成功！')
				if(this.rememberPassword){
            let notNewUser = false/*是否有存过该用户的密码*/
            if(this.passwordMessage && this.passwordMessage.length){
              this.passwordMessage.forEach(item => {
                if(item.username === this.loginForm.username && item.password === this.loginForm.password){ //如果用户名相同且密码相同，则不能再多存一次
                  notNewUser = true
                }
              });
            }
            if(!notNewUser){
              //如果没有存过该用户信息，才需要重新存（如果用户名相同，密码不同则更新密码,删除原有账户密码，存入新的账户密码）
              let loginForm = {...this.loginForm}
              this.passwordMessage.forEach((item, index) => {
                if(item.username === this.loginForm.username && item.password !== this.loginForm.password){
                  this.passwordMessage.splice(index, 1)
                }
              })
              this.passwordMessage.push(loginForm)
            }

						localStorage.setItem('usersMessage',JSON.stringify(this.passwordMessage))
            // store.set('usersMessage', this.passwordMessage)
          }else{
            if(this.passwordMessage && this.passwordMessage.length){
              this.passwordMessage.forEach((item, index) => {
                if(item.username === this.loginForm.username && item.password === this.loginForm.password){
                  this.passwordMessage.splice(index, 1)
                }
              });
            }

						localStorage.setItem('usersMessage',JSON.stringify(this.passwordMessage))
            // store.set('usersMessage', this.passwordMessage)
          }

					localStorage.setItem('rememberMe',this.rememberPassword)
					this.$router.push({ name: 'Home' })
			}).catch(err=>{
				this.loading = false
				this.$message.error('登录失败，请检查用户名或密码！')
          // 已经发出请求，处理请求结果
          // if (err.response) {
          //   this.$message.error('登录失败，请检查用户名或密码！')
          // } else {
          //   // Something happened in setting up the request that triggered an Error
          //   this.$message.error('登录失败：' + err.message)
          // }
				})
    },
    shutdown () {
      this.$confirm('此操作将关闭软件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      })
    }
  },
  created (){

		this.passwordMessage = JSON.parse(localStorage.getItem('usersMessage'))||[]
		this.rememberPassword = localStorage.getItem('rememberMe')
    // this.passwordMessage = store.get('usersMessage') || []
    // this.rememberPassword = store.get('rememberMe')
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.pt-login{
  width: 100vw;
  background-size: 100% 100%;
  height: 100vh;
  background: #2B9285;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
	font-size: 0;
	:global{
		.content-message{
			position: relative;
		width: 500px;
		}
	}
  .login-pf-page-header{
    max-width: 500px;
  }
  .shutdown{
    position: absolute;
    font-size: 60px;
    top: 10px;
    right: 10px;
    color: #fff;
  }
  .login-pf-page-hanjing {
    box-sizing: border-box;
    // width: 500px;
    max-width: 500px;
    padding: 30px 50px;
    background: #fff;
		border-radius: 0 0 5px 5px;
    .card-pf {
      padding: 10px 20px 30px 20px;
      margin-bottom: 0;
      border-radius: 15px;
      border: none;
      box-shadow: none;
      /* background-color: rgba(255, 255, 255, 0.6); */
      /* max-width: 480px; */
      padding: 0!important;
      margin: 0 auto;
      max-width: 500px;
      .title{
        margin: 0;
        font-size: 30px;
        font-weight: 400;
        color: #074379;
        line-height: 58px;
        letter-spacing: 10px;
        text-align: center;
      }
      .content{
        width: 100%;
        .form-item{
          margin-bottom: 35px;
          display: flex;
          background-color: #fff;
          padding-left: 10px;
          border-radius: 4px;
          align-items: center;
          img{
            position: relative;
            width: 25px;
            height: 25px;
            padding-right: 10px;
            // margin-right: 10px;
          }
          :global{
            .el-autocomplete{
              width: 100%;
            }
            .el-input__inner:focus{
              border: 2px solid black;
            }
            .wt-input{
              width: 100%;
            }
          }
        }
        .login-button{
          width: 100%;
          background-color: #13C4AE;
          /* padding: 15px; */
          padding: 12px 0;
          border-radius: 10px;
          font-size: 20px;
          color:#fff;
          border:none;
          cursor: pointer;
        }
				.button-div{
					padding-left: 10px;
				}
      } 
    }

  }
}
@media (min-width: 768px){
  .login-pf-page .card-pf {
    padding: 20px 40px 30px 40px;
  }
}

</style>
